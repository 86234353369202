<template>
  <v-data-table
    :headers="headers"
    :items="price_rates"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มอัตราเรทราคาสินค้า(ชิ้น)
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      :readonly="editedIndex >= 0"
                      v-model="product_type_id"
                      :items="product_types"
                      item-text="product_type_name"
                      item-value="product_type_id"
                      label="ประเภทสินค้า"
                    ></v-select>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.product_type_id.$silentErrors"
                      v-if="v$.product_type_id.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-btn
                      @click="addRate"
                      class="float-right" color="secondary">+
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="list && list.length > 0"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <table class="table">
                      <thead>
                      <tr>
                        <th width="30" class="text-center">#</th>
                        <th width="80" class="text-center">จำนวนชิ้น</th>
                        <th width="100" class="text-center">ราคาต่อชิ้น</th>
                        <th width="150" class="text-center">ราคารวม</th>
                        <th width="100" class="text-center">เครื่องมือ</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--            pattern_set_prices-->
                      <tr v-for="(item,index) in list">
                        <td> {{ index + 1 }}</td>
                        <td>

                          <v-text-field
                            v-model="item.amount"
                            type="number"
                            min="0"
                            placeholder="ราคาพิเศษสำหรับลายร้าน"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="text-center"
                            v-model="item.price"
                            type="number"
                            min="0"
                            placeholder="ราคาพิเศษสำหรับลายร้าน"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          {{
                            item.amount && item.price ? parseFloat((item.amount * item.price).toFixed(2)) : 0
                          }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            class="mr-2"
                            @click="deleteSubRate(index)"
                            small
                            color="danger">
                            {{ icons.mdiDelete }}
                          </v-icon>
                        </td>

                      </tr>
                      <tr v-if="list.length == 0">
                        <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                      </tbody>
                    </table>

                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.list.$silentErrors"
                      v-if="v$.list.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>

                    <hr class="mb-5" style="border: 0.5px solid lightgrey">

                    <!--                    <v-btn-->
                    <!--                      style="width: 100%"-->
                    <!--                      @click="save"-->
                    <!--                      color="primary">-->
                    <!--                      บันทึก-->
                    <!--                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="product_type_id || editedItem.product_type_id"
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ item }">
      {{ price_rates.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.promotion_free="{ item }">
      <span v-if="item.start_promotion && item.end_promotion">
        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>
        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}
      </span>
      <span v-if="!item.start_promotion && !item.end_promotion">
        -
      </span>
    </template>

    <template v-slot:item.status1="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.transport_type_id,1)"
        v-model="item.transport_status"
      ></v-checkbox>
    </template>

    <template v-slot:item.status2="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.transport_type_id,2)"
        v-model="item.transport_expense_status"
      ></v-checkbox>
    </template>
    <template v-slot:item.status3="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.transport_type_id,3)"
        v-model="item.transport_setadd_status"
      ></v-checkbox>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_price_rate from '../../../services/priceRate'
import instance_product_type from '../../../services/productType'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    price_rates: [],
    product_types: [],
    product_type_id: '',
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ประเภทสินค้า', value: 'product_type.product_type_name'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    list: [],
    editedIndex: -1,
    editedItem: {
      product_type_id: '',
      amount: 0,
      price: 0,
      list: [],
    },
    defaultItem: {
      product_type_id: '',
      amount: 0,
      price: 0,
      list: [],
    },
  }),
  validations() {
    return {
      list: {required},
      product_type_id: {required},
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม อัตราเรทราคาสินค้า(ชิ้น)' : 'แก้ไข อัตราเรทราคาสินค้า(ชิ้น)'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPriceRate()
    this.getProductType()
  },

  methods: {
    editItem(item) {
      this.v$.$reset()
      this.$store.state.isLoading = true

      instance_price_rate.show(item.id).then(res => {
        this.editedIndex = this.price_rates.indexOf(item)
        // this.editedItem = {...item}
        this.editedItem = {...item}
        this.list = res.data.data
        this.product_type_id = res.data.product_type
        this.dialog = true
        this.$store.state.isLoading = false
      }).catch(err => {

      });
    },

    deleteItem(item) {
      this.editedIndex = this.price_rates.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    deleteSubRate(index) {
      this.list.splice(index, 1)
    },
    addRate() {
      var tmp = {
        id: '',
        price_rate_id: '',
        amount: 0,
        price: 0,
      }
      this.list.push(tmp)
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_price_rate.destroy(this.editedItem).then(res => {
        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getPriceRate()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.$store.state.isLoading = false
      this.v$.$reset()
      this.dialog = false
      this.product_type_id = '';
      this.list = [];
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$store.state.isLoading = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true;

      var data = {
        id: this.editedItem.id,
        product_type_id: this.product_type_id,
        list: this.list,
      }
      if (this.editedIndex > -1) {
        instance_price_rate.update(data)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getPriceRate()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_price_rate.store(data)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getPriceRate()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getPriceRate() {
      var data = {
        search: '',
        date: '',
        status: '',
      }

      instance_price_rate.get(data).then(res => {
        this.price_rates = res.data.data;
      }).catch(err => {

      });
    },
    getProductType() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
        sortDesc: '',
        sortBy: '',
        page: 1,
        size: 99999999
      }

      instance_product_type.get(data).then(res => {
        this.product_types = res.data.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
