/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {

    return api.get(`price_rate/list?search=${data.search}&date=${data.date}&status=${data.status}`)
  },

  show(id){
    return api.get(`price_rate/show/${id}`)
  },

  store(data) {
    let getFormData = data
    let params = new FormData();

    params.append('product_type_id', getFormData['product_type_id']);

    for (var key in getFormData.list) {
      if (getFormData.list.hasOwnProperty(key)) {
        // params.append(key, getFormData[key]);
        params.append('amount[]', getFormData.list[key].amount);
        params.append('price[]', getFormData.list[key].price);
        params.append('price_rate_sub_id[]', getFormData.list[key].id);
      }
    }
    return api.post(`price_rate/store`, params, {})
  },

  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    params.append('price_rate_id', getFormData.product_type_id.product_type_id);
    console.log(getFormData,444)

    for (var key in getFormData.list) {
      if (getFormData.list.hasOwnProperty(key)) {
        // params.append(key, getFormData[key]);
        params.append('amount[]', getFormData.list[key].amount);
        params.append('price[]', getFormData.list[key].price);
        params.append('price_rate_sub_id[]', getFormData.list[key].id);
      }
    }
    return api.post(`price_rate/update/${data.id}`, params, {})
  },

  destroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.delete(`price_rate/delete/${data.id}`, params, {})
  },

}
